import React from 'react'
import ReactDOM from 'react-dom'
import detectPointer from '@insight/toolkit-utils/lib/events/detectPointer'
import { getEnvironmentName } from '@insight/toolkit-utils/lib/helpers/getEnvironmentName'

import App from './components/App'
import createModuleRegistry from './module-registry'
import { initLogRocket, initLaunchdarklyClient } from 'api'

const { registerModule, triggerExternalAction } = createModuleRegistry()

// Wait until the document has been fully parsed before rendering the app
document.addEventListener('DOMContentLoaded', () => {
  // append an empty div to the body to render into
  const element = document.createElement('div')
  document.body.appendChild(element)

  // render app into the dom
  ReactDOM.render(<App />, element)

  // Detect whether the user is using a pointer device (mouse or touch) and set
  // an 'is-using-pointer' class on the body as appropriate
  detectPointer()
  // init logrocket session recording
  if (window.getPrivacySettings) {
    if (getPrivacySettings().performance) {
      initLogRocket()
    }
  } else {
    console.warn('getPrivacySettings should be defined - initializing LR.')
    initLogRocket()
  }
})

initLaunchdarklyClient()
  .then((client) => {
    // This code can be removed after fusion, as of now it is interferring with the current EMEA site.
    if (client === undefined || client === null) {
      window.flags = {}
    } else {
      client.on('ready', () => {
        window.flags = client.allFlags()
        // pre-prod validation enablement
        if (
          getEnvironmentName(window.location.origin) === 'pre' &&
          window.flags['GNA-9004-CS-PRE']
        ) {
          window.flags['GNA-9004-CS'] = true
        }
      })
    }
  })
  .catch(() => {
    window.flags = {}
  })

// Export provided dependencies and shared-functions.
export default {
  React,
  ReactDOM,
  registerModule,
  triggerExternalAction,
}
