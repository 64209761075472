import {getEnvironmentName} from '@insight/toolkit-utils/lib/helpers/getEnvironmentName'

const envName = {
  'uat1.insight.com': 'NA-UAT',
  'loginas-uat1.insight.com': 'NA-UAT',
  'www.insight.com': 'NA-PROD',
  'pre.insight.com': 'NA-PRE-PROD',
  'ca.insight.com': 'NA-PROD',
  'sit.insight.com': 'NA-SIT',
  'loginas-sit.insight.com': 'NA-SIT',
  'projectqa.insight.com': 'NA-QA',
  'loginas-projectqa.insight.com': 'NA-QA',
  'uk-pre-qax.insight.com': 'QAX',
  'ie-pre-qax.insight.com': 'QAX',
  'at-pre-qax.insight.com': 'QAX',
  'be-pre-qax.insight.com': 'QAX',
  'se-pre-qax.insight.com': 'QAX',
  'fr-pre-qax.insight.com': 'QAX',
  'ch-pre-qax.insight.com': 'QAX',
  'de-pre-qax.insight.com': 'QAX',
  'es-pre-qax.insight.com': 'QAX',
  'it-pre-qax.insight.com': 'QAX',
  'uk-pre-qae.insight.com': 'QAE',
  'ie-pre-qae.insight.com': 'QAE',
  'at-pre-qae.insight.com': 'QAE',
  'be-pre-qae.insight.com': 'QAE',
  'se-pre-qae.insight.com': 'QAE',
  'fr-pre-qae.insight.com': 'QAE',
  'ch-pre-qae.insight.com': 'QAE',
  'de-pre-qae.insight.com': 'QAE',
  'es-pre-qae.insight.com': 'QAE',
  'it-pre-qae.insight.com': 'QAE',
  'uk-qax.insight.com': 'QAX',
  'ie-qax.insight.com': 'QAX',
  'at-qax.insight.com': 'QAX',
  'be-qax.insight.com': 'QAX',
  'se-qax.insight.com': 'QAX',
  'fr-qax.insight.com': 'QAX',
  'ch-qax.insight.com': 'QAX',
  'de-qax.insight.com': 'QAX',
  'es-qax.insight.com': 'QAX',
  'it-qax.insight.com': 'QAX',
  'uk-qae.insight.com': 'QAE',
  'ie-qae.insight.com': 'QAE',
  'at-qae.insight.com': 'QAE',
  'be-qae.insight.com': 'QAE',
  'se-qae.insight.com': 'QAE',
  'fr-qae.insight.com': 'QAE',
  'ch-qae.insight.com': 'QAE',
  'de-qae.insight.com': 'QAE',
  'es-qae.insight.com': 'QAE',
  'it-qae.insight.com': 'QAE',
  'uk.insight.com': 'EMEA-PROD',
  'ie.insight.com': 'EMEA-PROD',
  'at.insight.com': 'EMEA-PROD',
  'be.insight.com': 'EMEA-PROD',
  'se.insight.com': 'EMEA-PROD',
  'fr.insight.com': 'EMEA-PROD',
  'ch.insight.com': 'EMEA-PROD',
  'de.insight.com': 'EMEA-PROD',
  'es.insight.com': 'EMEA-PROD',
  'it.insight.com': 'EMEA-PROD',
  'uk-pre.insight.com': 'EMEA-PRE-PROD',
  'ie-pre.insight.com': 'EMEA-PRE-PROD',
  'at-pre.insight.com': 'EMEA-PRE-PROD',
  'be-pre.insight.com': 'EMEA-PRE-PROD',
  'se-pre.insight.com': 'EMEA-PRE-PROD',
  'fr-pre.insight.com': 'EMEA-PRE-PROD',
  'ch-pre.insight.com': 'EMEA-PRE-PROD',
  'de-pre.insight.com': 'EMEA-PRE-PROD',
  'es-pre.insight.com': 'EMEA-PRE-PROD',
  'it-pre.insight.com': 'EMEA-PRE-PROD',
}[window.document.domain] || 'DEV'

const logRocketId = {
  'NA-UAT': 'c6f0pz/insightcom-uat',
  'NA-PROD': 'c6f0pz/insightcom-prod',
  'NA-PRE-PROD': 'c6f0pz/insightcom-pre',
  'NA-QA': 'c6f0pz/insightcom-qa',
  'EMEA-PROD': 'c6f0pz/insightcom-emea-prod',
  'EMEA-PRE-PROD': 'c6f0pz/insightcom-emea-pre',
  'QAX': 'c6f0pz/insightcomqax',
  'QAE': 'c6f0pz/insightcom-qae',
}[envName]
const isLocalhost = window.location.origin.includes("localhost")
const launchDarklyClientId = isLocalhost ? '60f5d25b9707b8256057d316' : {
  'uat1': '60e89c6f12fd7324769711b6',
  'pre-uat1': '60e89c6f12fd7324769711b6',
  'sit': '60e89c8748987824b2884a43',
  'pre-sit': '60e89c8748987824b2884a43',
  'sim': '616a0e8b83658c0c863d528a',
  'pre-sim': '616a0e8b83658c0c863d528a',
  'projectqa': '60e89c502368f0250db211cd',
  'qa': '60e89c502368f0250db211cd',
  'pre-qa': '60e89c502368f0250db211cd',
  'qax': '60e89c502368f0250db211cd',
  'pre-qax': '60e89c502368f0250db211cd',
  'qae': '60e89c502368f0250db211cd',
  'pre-qae': '60e89c502368f0250db211cd',
  'prod': '60db9a5e64c60f258d31de49',
  'pre': '60db9a5e64c60f258d31de49',
}[getEnvironmentName(window.location.origin)]

const botUsers = /keynote.*|ascension123|cr10account/
const env = {
  envName,
  logRocketId,
  launchDarklyClientId,
  botUsers
}
Object.freeze(env)
export default env
